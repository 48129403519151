import { ReactNode, useCallback, useEffect, useRef } from "react";
import { Text } from "@olivahealth/oli-ui";

import SessionCard from "../../organisms/ProfessionalSessionCard/SessionCard";
import InfinityLoader from "../../molecules/Loaders/InfinityLoader";
import { Sessions } from "./useProfessionalDashboard";

import * as s from "./styles";

interface SessionListProps {
  sessions: Sessions;
  label: string;
  emptyListState?: ReactNode;
  onLoadMore?: () => void;
  hasMore?: boolean;
  isLoadingMore?: boolean;
}

export default function SessionList({
  sessions,
  label,
  emptyListState = null,
  onLoadMore,
  hasMore = false,
  isLoadingMore = false,
}: SessionListProps) {
  const observerRef = useRef<IntersectionObserver | null>(null);
  const loadMoreTriggerRef = useRef<HTMLDivElement>(null);

  const handleObserver = useCallback(
    (entries: IntersectionObserverEntry[]) => {
      const [entry] = entries;
      if (entry?.isIntersecting && hasMore && !isLoadingMore && onLoadMore) {
        onLoadMore();
      }
    },
    [hasMore, isLoadingMore, onLoadMore],
  );

  useEffect(() => {
    const currentTriggerRef = loadMoreTriggerRef.current;

    if (currentTriggerRef && onLoadMore) {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }

      observerRef.current = new IntersectionObserver(handleObserver, {
        rootMargin: "100px",
      });

      observerRef.current.observe(currentTriggerRef);
    }

    return () => {
      if (observerRef.current) {
        observerRef.current.disconnect();
      }
    };
  }, [handleObserver, onLoadMore]);

  return (
    <div className="mb-12">
      <Text variant="h3"> {label} </Text>
      {sessions?.length ? (
        <>
          <div className={s.sessionsCardWrapper}>
            {sessions.map((session) => (
              <SessionCard key={session.id} session={session} />
            ))}
          </div>
          {onLoadMore && (
            <div ref={loadMoreTriggerRef} className="h-10 w-full">
              {isLoadingMore && (
                <div className="py-4 flex justify-center">
                  <InfinityLoader size={40} />
                </div>
              )}
            </div>
          )}
        </>
      ) : (
        emptyListState
      )}
    </div>
  );
}
