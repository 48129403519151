import { graphql, useLazyLoadQuery, usePaginationFragment } from "react-relay";
import { isToday } from "date-fns";

import SessionStatus from "@olivahealth/graphql-server/src/domain/value-objects/SessionStatus";

import { OlivaHook } from "../../../hooks/OlivaHook";
import { useUserData } from "../../../services/contexts/UserDataContext";
import { useProfessionalSessionCard_fragment$key } from "../../organisms/ProfessionalSessionCard/__generated__/useProfessionalSessionCard_fragment.graphql";
import { useProfessionalDashboardQuery as IUseProfessionalDashboardQuery } from "./__generated__/useProfessionalDashboardQuery.graphql";
import { useProfessionalDashboardUpcoming_query$key } from "./__generated__/useProfessionalDashboardUpcoming_query.graphql";
import { useProfessionalDashboardPast_query$key } from "./__generated__/useProfessionalDashboardPast_query.graphql";
import { useProfessionalDashboardUpcomingPaginationQuery } from "./__generated__/useProfessionalDashboardUpcomingPaginationQuery.graphql";
import { useProfessionalDashboardPastPaginationQuery } from "./__generated__/useProfessionalDashboardPastPaginationQuery.graphql";

const NUMBER_OF_SESSIONS_SHOWN = 10;

const useProfessionalDashboardQuery = graphql`
  query useProfessionalDashboardQuery(
    $userId: String!
    $includePast: Boolean!
    $count: Int!
    $cursor: String
  ) {
    ...useProfessionalDashboardUpcoming_query
      @arguments(userId: $userId, count: $count, cursor: $cursor)
    ...useProfessionalDashboardPast_query
      @arguments(
        userId: $userId
        includePast: $includePast
        count: $count
        cursor: $cursor
      )
  }
`;

const useProfessionalDashboardUpcomingFragment = graphql`
  fragment useProfessionalDashboardUpcoming_query on Query
  @argumentDefinitions(
    userId: { type: "String!" }
    count: { type: "Int!" }
    cursor: { type: "String" }
  )
  @refetchable(queryName: "useProfessionalDashboardUpcomingPaginationQuery") {
    upcoming: sessions(
      filterBy: { userId: $userId }
      include: { cancelled: true }
      only: UPCOMING
      sortBy: { scheduledDate: ASC }
      first: $count
      after: $cursor
    ) @connection(key: "ProfessionalDashboard_upcoming") {
      edges {
        node {
          __typename
          ...useProfessionalSessionCard_fragment
          ... on Session {
            id
            status
            startDate
          }
          ... on GroupSession {
            id
            status
            startDate
          }
        }
      }
    }
  }
`;

const useProfessionalDashboardPastFragment = graphql`
  fragment useProfessionalDashboardPast_query on Query
  @argumentDefinitions(
    userId: { type: "String!" }
    includePast: { type: "Boolean!" }
    count: { type: "Int!" }
    cursor: { type: "String" }
  )
  @refetchable(queryName: "useProfessionalDashboardPastPaginationQuery") {
    past: sessions(
      filterBy: { userId: $userId }
      include: { cancelled: true }
      only: PAST
      sortBy: { scheduledDate: DESC }
      first: $count
      after: $cursor
    )
      @include(if: $includePast)
      @connection(key: "ProfessionalDashboard_past") {
      edges {
        node {
          __typename
          ...useProfessionalSessionCard_fragment
          ... on Session {
            id
            status
          }
          ... on GroupSession {
            id
            status
          }
        }
      }
    }
  }
`;

export type Sessions = Array<
  {
    readonly __typename: string;
    readonly id?: string;
    readonly status?: SessionStatus;
    readonly startDate?: string;
  } & useProfessionalSessionCard_fragment$key
>;

export interface UseProfessionalDashboardData {
  sessionsToday: Sessions;
  pastSessions: Sessions;
  upcomingSessions: Sessions;
  pastSessionsLength: number;
  upcomingSessionsLength: number;
  loadMorePast?: () => void;
  loadMoreUpcoming?: () => void;
  hasMorePast?: boolean;
  hasMoreUpcoming?: boolean;
  isLoadingMorePast?: boolean;
  isLoadingMoreUpcoming?: boolean;
}

type UseProfessionalDashboard = OlivaHook<UseProfessionalDashboardData>;

export default function useProfessionalDashboard(
  includePast?: boolean,
): UseProfessionalDashboard {
  const { data: user } = useUserData();

  const queryRef = useLazyLoadQuery<IUseProfessionalDashboardQuery>(
    useProfessionalDashboardQuery,
    {
      userId: user?.id ?? "",
      includePast: includePast ?? false,
      count: NUMBER_OF_SESSIONS_SHOWN,
    },
  );

  const {
    data: upcomingData,
    loadNext: loadMoreUpcoming,
    hasNext: hasMoreUpcoming,
    isLoadingNext: isLoadingMoreUpcoming,
  } = usePaginationFragment<
    useProfessionalDashboardUpcomingPaginationQuery,
    useProfessionalDashboardUpcoming_query$key
  >(useProfessionalDashboardUpcomingFragment, queryRef);

  const {
    data: pastData,
    loadNext: loadMorePast,
    hasNext: hasMorePast,
    isLoadingNext: isLoadingMorePast,
  } = usePaginationFragment<
    useProfessionalDashboardPastPaginationQuery,
    useProfessionalDashboardPast_query$key
  >(useProfessionalDashboardPastFragment, queryRef);

  const pastSessionsNodes =
    includePast && pastData?.past?.edges
      ? pastData.past.edges
          .map((edge) => edge.node)
          .filter((node) => Object.keys(node).length !== 0)
      : [];

  const upcomingSessionsNodes = upcomingData?.upcoming?.edges
    ? upcomingData.upcoming.edges
        .map((edge) => edge.node)
        .filter((node) => Object.keys(node).length !== 0)
    : [];

  const sessionsToday = upcomingSessionsNodes.filter((session) => {
    const isActive = session.status === SessionStatus.ACTIVE;
    const today = session.startDate && isToday(new Date(session.startDate));
    return isActive || today;
  });

  const notActiveUpcomingSessionsAfterToday = upcomingSessionsNodes.filter(
    (session) => {
      const notActive = session.status !== SessionStatus.ACTIVE;
      const notToday =
        session.startDate && !isToday(new Date(session.startDate));
      return notActive && notToday;
    },
  );

  return {
    status: "success",
    data: {
      sessionsToday,
      pastSessions: pastSessionsNodes,
      upcomingSessions: notActiveUpcomingSessionsAfterToday,
      pastSessionsLength: pastSessionsNodes.length || 0,
      upcomingSessionsLength: notActiveUpcomingSessionsAfterToday.length || 0,
      loadMorePast: () => {
        if (hasMorePast && !isLoadingMorePast && includePast) {
          loadMorePast(NUMBER_OF_SESSIONS_SHOWN);
        }
      },
      loadMoreUpcoming: () => {
        if (hasMoreUpcoming && !isLoadingMoreUpcoming) {
          loadMoreUpcoming(NUMBER_OF_SESSIONS_SHOWN);
        }
      },
      hasMorePast,
      isLoadingMorePast,
      hasMoreUpcoming,
      isLoadingMoreUpcoming,
    },
    effects: {},
  };
}
