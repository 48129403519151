/**
 * @generated SignedSource<<2f01841f08d95caed2324dc74d2b147d>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ReaderFragment, RefetchableFragment } from 'relay-runtime';
export type SessionStatusEnum = "ACTIVE" | "AWAITING_PAYMENT" | "CANCELLED" | "CANCELLED_LATE" | "COMPLETED" | "EMPLOYEE_NO_SHOW" | "NO_SHOW" | "PAYMENT_EXPIRED" | "PROFESSIONAL_NO_SHOW" | "SCHEDULED";
import { FragmentRefs } from "relay-runtime";
export type useProfessionalDashboardPast_query$data = {
  readonly past?: {
    readonly edges: ReadonlyArray<{
      readonly node: {
        readonly __typename: string;
        readonly id?: string;
        readonly status?: SessionStatusEnum;
        readonly " $fragmentSpreads": FragmentRefs<"useProfessionalSessionCard_fragment">;
      };
    }>;
  };
  readonly " $fragmentType": "useProfessionalDashboardPast_query";
};
export type useProfessionalDashboardPast_query$key = {
  readonly " $data"?: useProfessionalDashboardPast_query$data;
  readonly " $fragmentSpreads": FragmentRefs<"useProfessionalDashboardPast_query">;
};

const node: ReaderFragment = (function(){
var v0 = [
  "past"
],
v1 = [
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "id",
    "storageKey": null
  },
  {
    "alias": null,
    "args": null,
    "kind": "ScalarField",
    "name": "status",
    "storageKey": null
  }
];
return {
  "argumentDefinitions": [
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "count"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "cursor"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "includePast"
    },
    {
      "defaultValue": null,
      "kind": "LocalArgument",
      "name": "userId"
    }
  ],
  "kind": "Fragment",
  "metadata": {
    "connection": [
      {
        "count": "count",
        "cursor": "cursor",
        "direction": "forward",
        "path": (v0/*: any*/)
      }
    ],
    "refetch": {
      "connection": {
        "forward": {
          "count": "count",
          "cursor": "cursor"
        },
        "backward": null,
        "path": (v0/*: any*/)
      },
      "fragmentPathInResult": [],
      "operation": require('./useProfessionalDashboardPastPaginationQuery.graphql')
    }
  },
  "name": "useProfessionalDashboardPast_query",
  "selections": [
    {
      "condition": "includePast",
      "kind": "Condition",
      "passingValue": true,
      "selections": [
        {
          "alias": "past",
          "args": [
            {
              "fields": [
                {
                  "kind": "Variable",
                  "name": "userId",
                  "variableName": "userId"
                }
              ],
              "kind": "ObjectValue",
              "name": "filterBy"
            },
            {
              "kind": "Literal",
              "name": "include",
              "value": {
                "cancelled": true
              }
            },
            {
              "kind": "Literal",
              "name": "only",
              "value": "PAST"
            },
            {
              "kind": "Literal",
              "name": "sortBy",
              "value": {
                "scheduledDate": "DESC"
              }
            }
          ],
          "concreteType": "SessionsResponseConnection",
          "kind": "LinkedField",
          "name": "__ProfessionalDashboard_past_connection",
          "plural": false,
          "selections": [
            {
              "alias": null,
              "args": null,
              "concreteType": "SessionsResponseEdge",
              "kind": "LinkedField",
              "name": "edges",
              "plural": true,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "concreteType": null,
                  "kind": "LinkedField",
                  "name": "node",
                  "plural": false,
                  "selections": [
                    {
                      "alias": null,
                      "args": null,
                      "kind": "ScalarField",
                      "name": "__typename",
                      "storageKey": null
                    },
                    {
                      "args": null,
                      "kind": "FragmentSpread",
                      "name": "useProfessionalSessionCard_fragment"
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v1/*: any*/),
                      "type": "Session",
                      "abstractKey": null
                    },
                    {
                      "kind": "InlineFragment",
                      "selections": (v1/*: any*/),
                      "type": "GroupSession",
                      "abstractKey": null
                    }
                  ],
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "cursor",
                  "storageKey": null
                }
              ],
              "storageKey": null
            },
            {
              "alias": null,
              "args": null,
              "concreteType": "PageInfo",
              "kind": "LinkedField",
              "name": "pageInfo",
              "plural": false,
              "selections": [
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "endCursor",
                  "storageKey": null
                },
                {
                  "alias": null,
                  "args": null,
                  "kind": "ScalarField",
                  "name": "hasNextPage",
                  "storageKey": null
                }
              ],
              "storageKey": null
            }
          ],
          "storageKey": null
        }
      ]
    }
  ],
  "type": "Query",
  "abstractKey": null
};
})();

(node as any).hash = "fea333ceef82d3af95004739a2458a48";

export default node;
