import { useState } from "react";
import {
  BackgroundHeader,
  SingleSelectDropdown,
  SingleSelectDropdownItem,
} from "@olivahealth/oli-ui";

import useTranslation from "../../../hooks/useTranslation";
import { useUserData } from "../../../services/contexts/UserDataContext";
import PageLayout from "../../templates/Page";
import useProfessionalDashboard from "./useProfessionalDashboard";
import { DropdownState } from "./sharedTypes";
import NoSessions from "./NoSessions";
import NoSessionsToday from "./NoSessionsToday";
import ProfessionalNPSSurvey from "./ProfessionalNPSSurvey";
import SessionList from "./SessionList";

export default function ProfessionalDashboard() {
  const { t } = useTranslation("professionalDashboard");
  const { data: user } = useUserData();

  const [selected, setSelected] = useState<
    SingleSelectDropdownItem | undefined
  >();

  const {
    data: {
      sessionsToday,
      pastSessions,
      upcomingSessions,
      loadMorePast,
      hasMorePast,
      isLoadingMorePast,
      loadMoreUpcoming,
      hasMoreUpcoming,
      isLoadingMoreUpcoming,
    },
  } = useProfessionalDashboard(selected?.name === DropdownState.PAST);

  const isUpcomingTab = selected?.name === DropdownState.UPCOMING;

  const sessions = isUpcomingTab ? upcomingSessions : pastSessions;

  return (
    <>
      <BackgroundHeader
        imagePath="/img/img_background_dashboard.png"
        imageAlt={t("bannerImageAlt")}
        showBackLink={false}
        title={
          user?.name
            ? t("welcomeTitle", { name: user.name })
            : t("welcomeTitleNoName")
        }
      >
        <ProfessionalNPSSurvey />
      </BackgroundHeader>

      <PageLayout>
        <div className="mb-8">
          <SingleSelectDropdown
            title={t("filterDropdown.title")}
            handleItemChange={(item) => setSelected(item)}
            items={[
              {
                label: t("filterDropdown.itemUpcomingLabel"),
                name: DropdownState.UPCOMING,
                selected: true,
              },
              {
                label: t("filterDropdown.itemPastLabel"),
                name: DropdownState.PAST,
                selected: false,
              },
            ]}
          />
        </div>
        {isUpcomingTab && (
          <SessionList
            sessions={sessionsToday}
            label={t("todayHeader")}
            emptyListState={<NoSessionsToday />}
          />
        )}
        <SessionList
          sessions={sessions}
          label={isUpcomingTab ? t("upcomingHeader") : t("pastHeader")}
          emptyListState={
            <NoSessions dropdownState={selected?.name as DropdownState} />
          }
          onLoadMore={isUpcomingTab ? loadMoreUpcoming : loadMorePast}
          hasMore={isUpcomingTab ? hasMoreUpcoming : hasMorePast}
          isLoadingMore={
            isUpcomingTab ? isLoadingMoreUpcoming : isLoadingMorePast
          }
        />
      </PageLayout>
    </>
  );
}
