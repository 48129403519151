/**
 * @generated SignedSource<<70ed39f4e36b52be5a70490107d626e5>>
 * @lightSyntaxTransform
 * @nogrep
 */

/* tslint:disable */
/* eslint-disable */
// @ts-nocheck

import { ConcreteRequest, Query } from 'relay-runtime';
import { FragmentRefs } from "relay-runtime";
export type useProfessionalDashboardPastPaginationQuery$variables = {
  count: number;
  cursor?: string | null | undefined;
  includePast: boolean;
  userId: string;
};
export type useProfessionalDashboardPastPaginationQuery$data = {
  readonly " $fragmentSpreads": FragmentRefs<"useProfessionalDashboardPast_query">;
};
export type useProfessionalDashboardPastPaginationQuery = {
  response: useProfessionalDashboardPastPaginationQuery$data;
  variables: useProfessionalDashboardPastPaginationQuery$variables;
};

const node: ConcreteRequest = (function(){
var v0 = [
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "count"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "cursor"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "includePast"
  },
  {
    "defaultValue": null,
    "kind": "LocalArgument",
    "name": "userId"
  }
],
v1 = {
  "kind": "Variable",
  "name": "userId",
  "variableName": "userId"
},
v2 = [
  {
    "kind": "Variable",
    "name": "after",
    "variableName": "cursor"
  },
  {
    "fields": [
      (v1/*: any*/)
    ],
    "kind": "ObjectValue",
    "name": "filterBy"
  },
  {
    "kind": "Variable",
    "name": "first",
    "variableName": "count"
  },
  {
    "kind": "Literal",
    "name": "include",
    "value": {
      "cancelled": true
    }
  },
  {
    "kind": "Literal",
    "name": "only",
    "value": "PAST"
  },
  {
    "kind": "Literal",
    "name": "sortBy",
    "value": {
      "scheduledDate": "DESC"
    }
  }
],
v3 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "__typename",
  "storageKey": null
},
v4 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "id",
  "storageKey": null
},
v5 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "status",
  "storageKey": null
},
v6 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "endDate",
  "storageKey": null
},
v7 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "startDate",
  "storageKey": null
},
v8 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancelledByName",
  "storageKey": null
},
v9 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "cancelledById",
  "storageKey": null
},
v10 = {
  "alias": null,
  "args": null,
  "kind": "ScalarField",
  "name": "language",
  "storageKey": null
},
v11 = [
  (v4/*: any*/),
  (v5/*: any*/)
];
return {
  "fragment": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Fragment",
    "metadata": null,
    "name": "useProfessionalDashboardPastPaginationQuery",
    "selections": [
      {
        "args": [
          {
            "kind": "Variable",
            "name": "count",
            "variableName": "count"
          },
          {
            "kind": "Variable",
            "name": "cursor",
            "variableName": "cursor"
          },
          {
            "kind": "Variable",
            "name": "includePast",
            "variableName": "includePast"
          },
          (v1/*: any*/)
        ],
        "kind": "FragmentSpread",
        "name": "useProfessionalDashboardPast_query"
      }
    ],
    "type": "Query",
    "abstractKey": null
  },
  "kind": "Request",
  "operation": {
    "argumentDefinitions": (v0/*: any*/),
    "kind": "Operation",
    "name": "useProfessionalDashboardPastPaginationQuery",
    "selections": [
      {
        "condition": "includePast",
        "kind": "Condition",
        "passingValue": true,
        "selections": [
          {
            "alias": "past",
            "args": (v2/*: any*/),
            "concreteType": "SessionsResponseConnection",
            "kind": "LinkedField",
            "name": "sessions",
            "plural": false,
            "selections": [
              {
                "alias": null,
                "args": null,
                "concreteType": "SessionsResponseEdge",
                "kind": "LinkedField",
                "name": "edges",
                "plural": true,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "concreteType": null,
                    "kind": "LinkedField",
                    "name": "node",
                    "plural": false,
                    "selections": [
                      (v3/*: any*/),
                      {
                        "kind": "InlineFragment",
                        "selections": [
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v4/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "type",
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "checkoutUrl",
                                "storageKey": null
                              },
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "employee",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "name",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "surname",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": "UserSettings",
                                    "kind": "LinkedField",
                                    "name": "settings",
                                    "plural": false,
                                    "selections": [
                                      {
                                        "alias": null,
                                        "args": null,
                                        "kind": "ScalarField",
                                        "name": "hasGivenSessionSummarisationConsent",
                                        "storageKey": null
                                      }
                                    ],
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "SessionAppointment",
                                "kind": "LinkedField",
                                "name": "appointment",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "rescheduledPreviousStartDate",
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v8/*: any*/),
                              (v9/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "AutomatedSessionSummary",
                                "kind": "LinkedField",
                                "name": "automatedSummary",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "concreteType": null,
                                    "kind": "LinkedField",
                                    "name": "summary",
                                    "plural": false,
                                    "selections": [
                                      (v3/*: any*/)
                                    ],
                                    "storageKey": null
                                  }
                                ],
                                "storageKey": null
                              },
                              (v10/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "kind": "ScalarField",
                                "name": "transcriptionStatus",
                                "storageKey": null
                              }
                            ],
                            "type": "Session",
                            "abstractKey": null
                          },
                          {
                            "kind": "InlineFragment",
                            "selections": [
                              (v4/*: any*/),
                              (v5/*: any*/),
                              (v6/*: any*/),
                              (v7/*: any*/),
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "Workshop",
                                "kind": "LinkedField",
                                "name": "workshop",
                                "plural": false,
                                "selections": [
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "title",
                                    "storageKey": null
                                  },
                                  {
                                    "alias": null,
                                    "args": null,
                                    "kind": "ScalarField",
                                    "name": "illustrationCard",
                                    "storageKey": null
                                  },
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              {
                                "alias": null,
                                "args": null,
                                "concreteType": "User",
                                "kind": "LinkedField",
                                "name": "participants",
                                "plural": true,
                                "selections": [
                                  (v4/*: any*/)
                                ],
                                "storageKey": null
                              },
                              (v8/*: any*/),
                              (v9/*: any*/),
                              (v10/*: any*/)
                            ],
                            "type": "GroupSession",
                            "abstractKey": null
                          }
                        ],
                        "type": "SessionResponse",
                        "abstractKey": "__isSessionResponse"
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v11/*: any*/),
                        "type": "Session",
                        "abstractKey": null
                      },
                      {
                        "kind": "InlineFragment",
                        "selections": (v11/*: any*/),
                        "type": "GroupSession",
                        "abstractKey": null
                      }
                    ],
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "cursor",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              },
              {
                "alias": null,
                "args": null,
                "concreteType": "PageInfo",
                "kind": "LinkedField",
                "name": "pageInfo",
                "plural": false,
                "selections": [
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "endCursor",
                    "storageKey": null
                  },
                  {
                    "alias": null,
                    "args": null,
                    "kind": "ScalarField",
                    "name": "hasNextPage",
                    "storageKey": null
                  }
                ],
                "storageKey": null
              }
            ],
            "storageKey": null
          },
          {
            "alias": "past",
            "args": (v2/*: any*/),
            "filters": [
              "filterBy",
              "include",
              "only",
              "sortBy"
            ],
            "handle": "connection",
            "key": "ProfessionalDashboard_past",
            "kind": "LinkedHandle",
            "name": "sessions"
          }
        ]
      }
    ]
  },
  "params": {
    "cacheID": "de4b3538daf5abf4bd094f54756f100b",
    "id": null,
    "metadata": {},
    "name": "useProfessionalDashboardPastPaginationQuery",
    "operationKind": "query",
    "text": "query useProfessionalDashboardPastPaginationQuery(\n  $count: Int!\n  $cursor: String\n  $includePast: Boolean!\n  $userId: String!\n) {\n  ...useProfessionalDashboardPast_query_WxL5y\n}\n\nfragment useProfessionalDashboardPast_query_WxL5y on Query {\n  past: sessions(filterBy: {userId: $userId}, include: {cancelled: true}, only: PAST, sortBy: {scheduledDate: DESC}, first: $count, after: $cursor) @include(if: $includePast) {\n    edges {\n      node {\n        __typename\n        ...useProfessionalSessionCard_fragment\n        ... on Session {\n          id\n          status\n        }\n        ... on GroupSession {\n          id\n          status\n        }\n      }\n      cursor\n    }\n    pageInfo {\n      endCursor\n      hasNextPage\n    }\n  }\n}\n\nfragment useProfessionalSessionCard_fragment on SessionResponse {\n  __isSessionResponse: __typename\n  __typename\n  ... on Session {\n    id\n    type\n    checkoutUrl\n    status\n    endDate\n    startDate\n    employee {\n      name\n      surname\n      settings {\n        hasGivenSessionSummarisationConsent\n      }\n      id\n    }\n    appointment {\n      rescheduledPreviousStartDate\n    }\n    cancelledByName\n    cancelledById\n    automatedSummary {\n      summary {\n        __typename\n        ... on ProcessedProfessionalSessionSummary {\n          __typename\n        }\n      }\n    }\n    language\n    transcriptionStatus\n  }\n  ... on GroupSession {\n    id\n    status\n    endDate\n    startDate\n    workshop {\n      title\n      illustrationCard\n      id\n    }\n    participants {\n      id\n    }\n    cancelledByName\n    cancelledById\n    language\n  }\n}\n"
  }
};
})();

(node as any).hash = "fea333ceef82d3af95004739a2458a48";

export default node;
